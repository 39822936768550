<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <i class="wizard-icon flaticon-list"></i>
                <h3 class="wizard-title">1. Enter Details</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-responsive"></i>
                <h3 class="wizard-title">2. Select Services</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-truck"></i>
                <h3 class="wizard-title">3. Delivery Address</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div> -->
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-globe"></i>
                <h3 class="wizard-title">3. Review and Submit</h3>
              </div>
              <i class="wizard-arrow last flaticon2-next"></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Enter the Details of this virtual device
                </h4>
                <p>
                  In this wizard you will create a custom device that you can
                  use for your own devices or APIs
                </p>
                <div class="form-group">
                  <label>Location of device</label>
                  <b-form-input
                    id="device-location"
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Where is this device located? ie Kitchen, garage, etc"
                    v-model="device_location"
                    :state="deviceLocationValidation"
                    aria-describedby="device-location-feedback"
                    trim
                  />
                  <b-form-invalid-feedback id="device-location-feedback">
                    Enter at least 5 letters
                  </b-form-invalid-feedback>
                  <span class="form-text text-muted"
                    >Where is this device located? ie Kitchen, garage, etc</span
                  >
                </div>
                <div class="form-group">
                  <label>Name of the device</label>
                  <b-form-input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Name"
                    value="Bulb"
                    v-model="device_name"
                    :state="deviceNameValidation"
                    aria-describedby="device-name-feedback"
                    trim
                  />
                  <b-form-invalid-feedback id="device-name-feedback">
                    Enter at least 5 letters
                  </b-form-invalid-feedback>
                  <span class="form-text text-muted"
                    >Write the name of the device, ie bulb, lamp, leds etc
                  </span>
                </div>
                <!-- <div class="form-text">Device type</div> -->
                <div class="form-group">
                  <label>Device type</label>
                  <select
                    v-model="device_type"
                    name="country"
                    class="form-control form-control-solid form-control-lg"
                  >
                    <option value="">Select</option>
                    <option value="Bulb">Bulb</option>
                    <option value="Thermostat">Thermostat</option>
                    <option value="Video">Video</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="device_description"
                    placeholder="Enter something that describes your device..."
                    rows="3"
                    :state="deviceDescriptionValidation"
                    max-rows="6"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="device-name-feedback">
                    Enter at least 10 letters
                  </b-form-invalid-feedback>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Select your notification schema
                </h4>
                <div class="form-group">
                  <b-form-group label="Notify online/offline:">
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="notifications_selected"
                        name="flavour-2"
                      >
                        <b-form-checkbox value="email">Email</b-form-checkbox>
                        <b-form-checkbox value="messenger">FB Messenger</b-form-checkbox>
                        <b-form-checkbox value="twitter">Twitter</b-form-checkbox>
                        <b-form-checkbox value="telegram">Telegram</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                    <div>Selected: <strong> {{ notifications_selected }} </strong></div>
                </div>
                <div class="form-group">
                  <label>Is this device public?</label>
                  <select
                    name="packaging"
                    class="form-control form-control-solid form-control-lg"
                    v-model="device_public"
                  >
                    <option value="">Select availability</option>
                    <option value="No" 
                    >No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <!-- <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Setup Your Delivery Location
                </h4>
                <div class="form-group">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="locaddress1"
                    placeholder="Address Line 1"
                    value="Address Line 1"
                  />
                  <span class="form-text text-muted"
                    >Please enter your Address.</span
                  >
                </div>
                <div class="form-group">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="locaddress2"
                    placeholder="Address Line 2"
                    value="Address Line 2"
                  />
                  <span class="form-text text-muted"
                    >Please enter your Address.</span
                  >
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Postcode</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="locpostcode"
                        placeholder="Postcode"
                        value="3072"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Postcode.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="loccity"
                        placeholder="City"
                        value="Preston"
                      />
                      <span class="form-text text-muted"
                        >Please enter your City.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>State</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="locstate"
                        placeholder="State"
                        value="VIC"
                      />
                      <span class="form-text text-muted"
                        >Please enter your State.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Country</label>
                      <select
                        name="loccountry"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="">Select</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Åland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU" 
                        >Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO"
                          >Bolivia, Plurinational State of</option
                        >
                        <option value="BQ"
                          >Bonaire, Sint Eustatius and Saba</option
                        >
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO"
                          >British Indian Ocean Territory</option
                        >
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD"
                          >Congo, the Democratic Republic of the</option
                        >
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM"
                          >Heard Island and McDonald Islands</option
                        >
                        <option value="VA"
                          >Holy See (Vatican City State)</option
                        >
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP"
                          >Korea, Democratic People's Republic of</option
                        >
                        <option value="KR">Korea, Republic of</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA"
                          >Lao People's Democratic Republic</option
                        >
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK"
                          >Macedonia, the former Yugoslav Republic of</option
                        >
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM"
                          >Micronesia, Federated States of</option
                        >
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS"
                          >Palestinian Territory, Occupied</option
                        >
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Réunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH"
                          >Saint Helena, Ascension and Tristan da Cunha</option
                        >
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC"
                          >Saint Vincent and the Grenadines</option
                        >
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS"
                          >South Georgia and the South Sandwich Islands</option
                        >
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM"
                          >United States Minor Outlying Islands</option
                        >
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE"
                          >Venezuela, Bolivarian Republic of</option
                        >
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.S.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> -->
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Review your virtual device details and Submit
                </h4>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">
                    Device details
                  </div>
                  <div class="line-height-md">
                    {{device_name}} in {{device_location}}
                    <br />
                    Technically identified as {{device_type}}<br />
                    {{device_description}}
                  </div>
                </div>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">
                    Notification settings:
                  </div>
                  <div class="line-height-md">
                    {{ notifications_selected }}
                    <br />
                    Public device: {{ device_public }} <br />

                  </div>
                </div>
                <!-- <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">
                    Delivery Service Type:
                  </div>
                  <div class="line-height-md">
                    Overnight Delivery with Regular Packaging
                    <br />
                    Preferred Morning (8:00AM - 11:00AM) Delivery
                  </div>
                </div> -->
                <!-- <div class="mb-5">
                  <div class="font-weight-bold mb-3">
                    Delivery Address:
                  </div>
                  <div class="line-height-md">
                    Address Line 1
                    <br />
                    Address Line 2 <br />
                    Preston 3072, VIC, Australia
                  </div>
                </div> -->
              </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Submit
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Next Step
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_VIRTUAL_DEVICE, SET_ERROR } from "@/core/services/store/devices.module"
import { DEVICES_TECHNICAL_NAMES } from "@/core/enums/devices-technical-names.enum"
import { SOURCES } from "@/core/enums/sources.enum"
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

export default {
  name: "Wizard-1",
  computed: {
     deviceLocationValidation() {
       return this.device_location.length > 5 ? true : false
     },
     deviceNameValidation() {
       return this.device_name.length > 5 ? true : false
     },
     deviceDescriptionValidation() {
       return this.device_description.length > 10 ? true : false
     }
   },
  mounted() {
        this.$store.subscribe((mutation,state)=>{
      // console.log("mutation-->",mutation.type, state)
      if(mutation.type === SET_ERROR){
        state;
        Swal.fire({
        title: "",
        text: "The application found an issue on the last operation",
        icon: "warning",
        confirmButtonClass: "btn btn-secondary",
      });
      }
    })
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard" },
      { title: "Wizard-1" },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });
    this.global_wizard = wizard;

    // Validation before going to next page
    wizard.on("change", (wizardObj) =>{
      // validate the form and use below function to stop the wizard's step
      if(wizardObj.getStep() == 1 && this.validateStepOne()==false){
        wizardObj.stop();
        Swal.fire({
          title: "",
          text: "There is still some information missing",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else if(wizardObj.getStep() == 2 && this.validateStepTwo()==false){
          wizardObj.stop();
          Swal.fire({
            title: "",
            text: "Select device availability",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
      }
      
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  data() {
    return {
      global_wizard: {},
      notifications_selected: [],
      device_description: "",
      device_name: "",
      device_location: "",
      device_type: "",
      device_public: ""
    }
  },
  methods: {
    validateStepOne: function(){
      if(this.device_description.length < 10
      || this.device_name.length < 5
      || this.device_location < 5
      || this.device_type == ""){
        return false;
      } else {
        return true;
      }
    },
    validateStepTwo: function(){
      if(this.device_public != ""){
        return true;
      }
      return false;
    },
    submit: function(e) {
      e.preventDefault();
      // here we create the object to send
      this.$store.dispatch(CREATE_VIRTUAL_DEVICE,{
        // token: token
        // secret_token: secret_token
        // claim: 'yes'
        source: SOURCES.WEB,
        anet_device:{object:this.device_name,
        location: this.device_location,
        description: this.device_description,
        is_public: this.device_public == 'Yes' ? '1' : "0",
        notify_email: this.notifications_selected.indexOf('email') != -1 ? '1' : '0',
        notify_twitter: this.notifications_selected.indexOf('twitter') != -1 ? '1' : '0',
        notify_telegram: this.notifications_selected.indexOf('telegram') != -1 ? '1' : '0',
        version: DEVICES_TECHNICAL_NAMES[this.device_type],
        notify_fbinbox: this.notifications_selected.indexOf('messenger') != -1 ? '1' : '0'}}
      ).then((resultOfCreation)=>{
        if (resultOfCreation.result == "success") {
          Swal.fire({
            title: "",
            text: "The application has been successfully submitted!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          }); 
          //if everything went smooth, then remove all information and take the user to step 1 in case
          //he wants to add another one
          this.global_wizard.goTo(1); 
          this.notifications_selected = [];
          this.device_location = '';
          this.device_description = '';
          this.device_public = '';
          this.device_name = '';
          this.device_type = '';
        } else {
          Swal.fire({
            title: "",
            text: "The application found an issue on the last operation",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
          });
        }
    })
    },
  },
};
</script>
